// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import '../node_modules/ng-zorro-antd/ng-zorro-antd.less';

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
/* You can add global styles to this file, and also import other style files */

@primary-color: #274df7;
@red-color: #ff4d4f;
@layout-body-background: #ffffff;
@layout-header-background: #383838;

.float-right {
  float: right;
}

.action-button {
  margin-right: 8px;
}

.popover-wrap {
  width: 350px;
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.mb-0 {
  margin-bottom: 0;
}

.empty-30 {
  height: 30px;
}

.red-color {
  color: @red-color;
}